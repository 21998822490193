import Navbar from '../components/navbar/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import { MetaTags } from 'react-meta-tags'

const ERPSolutions = () => {
  return (
    <>
      <MetaTags>
        <title>ERP Solutions - NewItZone</title>
        <meta name='description' content="Fixed price - for time-bound services with clearly defined deliverables: a feasibility study, solution prototyping, market research. Time & Material - for long-term involvements when the service scope can't be estimated accurately at the start: an operational review of an IT department, an IT audit, modernization of a bunch of business apps." />
        <meta name='keywords' content='HTML, CSS, JavaScript,react,node,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution'></meta>
      </MetaTags>
      <Navbar />
      <div id='about' className='section wb'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_05.png' alt='Sales Force Automation logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>ERP Solution</Link>
                  </h3>
                  <p>A single source of truth is created by combining daily data from several departments using enterprise resource planning software, which is advantageous to the whole company. Tools for enterprise resource planning</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_06.png' alt='Escape Enterprise Resource Planning logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>FCA ERP</Link>
                  </h3>
                  <p>Escape Enterprise Resource Planning (ERP) software designed to meet the various operational and functional requirements of a medium and large size business.Our ERP software application acts</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_07.png' alt='A human resource management payroll software logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>Web Based Payroll.</Link>
                  </h3>
                  <p>A human resource management payroll software, or HRMS as it is more frequently known, is a collection of all the programs and procedures required to effectively run the HR division of an organization</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_08.png' alt='Tally Prime software logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>Tally Prime </Link>
                  </h3>
                  <p>At Tally, we have a hard-earned reputation for empowering businesses with stable, effective software products. Tally Prime has all the features required for high-performance business management.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ERPSolutions
