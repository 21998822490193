import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { Link } from 'react-router-dom'
import { MetaTags } from 'react-meta-tags'

const AboutUsScreen = () => {
  return (
    <div>
      <MetaTags>
        <title>About - NewItZone</title>
        <meta
          name='description'
          content='At newitzone, we are dedicated to providing you with the
                  highest quality software solutions that are tailored to fit
                  your individual needs and business goals.'
        />
      </MetaTags>
      <Navbar />
      <div id='about' className='section wb'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='message-box'>
                <h4>About Us</h4>
                <h2>Creating a Digital World</h2>
                <p className='lead'>At newitzone, we are dedicated to providing you with the highest quality software solutions that are tailored to fit your individual needs and business goals.</p>

                <p>We offer a wide range of technologies and development services, including HTML, CSS, JavaScript, Node.js, and more. Our experienced developers can create custom solutions that are tailored to your specific needs. Our software solutions are designed for smooth integration in existing systems and platforms for reliable performance. We can help you ensure seamless integration and smooth transition from legacy systems or platforms.</p>

                <Link to='/contact' className='btn btn-light btn-radius btn-brd grd1'>
                  Contact us
                </Link>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='post-media wow fadeIn'>
                <img src='/public/uploads/about_01.jpg' alt='about_01' className='img-responsive img-rounded' />
                <Link to='/' data-rel='prettyPhoto[gal]' className='playbutton'>
                  <i className='flaticon-play-button'></i>
                </Link>
              </div>
            </div>
          </div>

          <hr className='hr1' />

          <div className='row'>
            <div className='col-md-6'>
              <div className='post-media wow fadeIn'>
                <img src='/public/uploads/about_02.jpg' alt='' className='img-responsive img-rounded' />
              </div>
            </div>

            <div className='col-md-6'>
              <div className='message-box'>
                <h4>Who We are</h4>
                <h2>We Are Good WEB Solutions</h2>
                <p className='lead'>Thousand of our client is using our low price services which including website design, web development, web hosting, e-commerce website development, App development and giving the free recommendation to endorse the services all across the world</p>

                <p>We provide website design and development services using the latest coding languages including HTML5, CSS3, JavaScript and PHP, with thorough testing before launch.</p>

                <Link to='/contact' className='btn btn-light btn-radius btn-brd grd1'>
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUsScreen
