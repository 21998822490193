import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomeScreen from './Screen/HomeScreen';
import AboutUsScreen from './Screen/AboutUsScreen';
import Contact from './Screen/Contact';
import Portfolio from './Screen/Portfolio';
import ServiceScreen from './Screen/ServiceScreen';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SalesTrendz from './Screen/SalesTrendz';
import ERPSolutions from './Screen/ERPSolutions';
import RetailSolutions from './Screen/RetailSolutions';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/about" element={<AboutUsScreen />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/services" element={<ServiceScreen />} />
            {/* <Route path="/testimonials" element={<TestimonialScreen />} /> */}
            <Route path="/Sales-Trendz" element={<SalesTrendz />} />
            <Route path="/ERP-Solutions" element={<ERPSolutions />} />
            <Route path="/Retail-Solutions" element={<RetailSolutions />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
