import { useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Service from '../components/Service/Service'
import Footer from '../components/footer/Footer'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { MetaTags } from 'react-meta-tags'

const Portfolio = () => {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: false,

    responsive: {
      0: {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
      },
      1000: {
        items: 2,
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
      },
      1200: {
        items: 3,
        loop: 0,
        autoplay: 0,
      },
    },
  }
  const [open, setOpen] = useState(false)
  const [fileName, setFileName] = useState(null)

  const onOpenModal = (url) => {
    setOpen(true)
    setFileName(url)
  }
  const onCloseModal = () => setOpen(false)

  return (
    <div>
      <MetaTags>
        <title>Portfolio - NewItZone</title>
        <meta name='description' content="Need a website but don't know where to start? We work with different technology." />
        <meta name='keywords' content='HTML, CSS, JavaScript,react,node,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution'></meta>
      </MetaTags>
      <Navbar />
      <div id='portfolio' className='section wb'>
        <div className='container'>
          <div className='section-title text-center'>
            <h3>Our Portfolio</h3>
            <p className='lead'>
              We build professional website templates, web design projects, material designs and UI kits. <br />
              Some of our awesome completed projects in below.
            </p>
          </div>
        </div>
        <div className='portfolio-item'>
          <OwlCarousel className='owl-theme' {...options}>
            <div className='item'>
              <div className='service-widget'>
                <div className='post-media wow fadeIn'>
                  <button onClick={() => onOpenModal('/public/uploads/p1.jpg')} className='hoverbutton global-radius'>
                    <i className='flaticon-unlink'></i>
                  </button>
                  <img src='/public/uploads/p1.jpg' alt='' className='img-responsive img-rounded' />
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='service-widget'>
                <div className='post-media wow fadeIn'>
                  <button onClick={() => onOpenModal('/public/uploads/p2.jpg')} className='hoverbutton global-radius'>
                    <i className='flaticon-unlink'></i>
                  </button>
                  <img src='/public/uploads/p2.jpg' alt='' className='img-responsive img-rounded' />
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='service-widget'>
                <div className='post-media wow fadeIn'>
                  <button onClick={() => onOpenModal('/public/uploads/p3.jpg')} className='hoverbutton global-radius'>
                    <i className='flaticon-unlink'></i>
                  </button>
                  <img src='/public/uploads/p3.jpg' alt='' className='img-responsive img-rounded' />
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '5px',
            marginRight: '5px',
          }}>
          <OwlCarousel className='owl-theme' {...options}>
            <div className='item'>
              <div className='service-widget'>
                <div className='post-media wow fadeIn'>
                  <button onClick={() => onOpenModal('/public/uploads/p4.jpg')} className='hoverbutton global-radius'>
                    <i className='flaticon-unlink'></i>
                  </button>
                  <img src='/public/uploads/p4.jpg' alt='' className='img-responsive img-rounded' />
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='service-widget'>
                <div className='post-media wow fadeIn'>
                  <button onClick={() => onOpenModal('/public/uploads/p5.jpg')} className='hoverbutton global-radius'>
                    <i className='flaticon-unlink'></i>
                  </button>
                  <img src='/public/uploads/p5.jpg' alt='' className='img-responsive img-rounded' />
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='service-widget'>
                <div className='post-media wow fadeIn'>
                  <button onClick={() => onOpenModal('/public/uploads/p6.jpg')} className='hoverbutton global-radius'>
                    <i className='flaticon-unlink'></i>
                  </button>
                  <img src='/public/uploads/p6.jpg' alt='' className='img-responsive img-rounded' />
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <Service />
      <Footer />
      {open ? (
        <Modal open={open} onClose={onCloseModal} center>
          <img src={fileName} alt='' className='img-responsive img-rounded' />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default Portfolio
