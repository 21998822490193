import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Service = () => {
  const options = {
    margin: 12,
    loop: true,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
    },
  };
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(null);

  const onOpenModal = (url) => {
    setOpen(true);
    setFileName(url);
  };
  const onCloseModal = () => setOpen(false);
  return (
    <div>
      <div id="services" className="parallax section lb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Our Service</h3>
            <p className="lead">
              We offer a wide range of technologies such as software
              development, SEO development, CRM development, website
              development, etc. and development services including HTML, CSS,
              JavaScript, Node.js, React, Angular, and more. Our team of
              experienced developers is dedicated to delivering high quality
              solutions that meet your unique business needs.
            </p>
          </div>

          <OwlCarousel className="owl-theme" {...options}>
            <div className="item">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <button
                    onClick={() =>
                      onOpenModal('/public/uploads/service_01.jpg')
                    }
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink"></i>
                  </button>
                  <img
                    src="/public/uploads/service_01.jpg"
                    alt=""
                    className="img-responsive img-rounded "
                    style={{ height: '230px' }}
                  />
                </div>
                <div className="service-dit">
                  <h3>WEBSITE DEVELOPMENT</h3>
                  <p>
                    We specialize in building custom websites that are not only
                    visually appealing but also highly functional. Our team uses
                    the latest web technologies, such as Node.js, React,
                    Angular, and more, to create websites
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <button
                    onClick={() =>
                      onOpenModal('/public/uploads/service_02.jpg')
                    }
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink"></i>
                  </button>
                  <img
                    src="/public/uploads/service_02.jpg"
                    alt=""
                    className="img-responsive img-rounded"
                    style={{ height: '230px' }}
                  />
                </div>
                <div className="service-dit">
                  <h3>SOFTWARE DEVELOPMENT</h3>
                  <p>
                    Our software development team has extensive experience in
                    creating custom applications for multiple industries,
                    working closely with clients to create tailored solutions
                    based on their individual needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <button
                    onClick={() =>
                      onOpenModal('/public/uploads/service_03.jpg')
                    }
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink"></i>
                  </button>
                  <img
                    src="/public/uploads/service_03.jpg"
                    alt=""
                    className="img-responsive img-rounded"
                    style={{ height: '230px' }}
                  />
                </div>
                <div className="service-dit">
                  <h3>CRM DEVELOPMENT</h3>
                  <p>
                    At our company, we specialize in developing CRM solutions
                    that enable businesses to streamline and optimize their
                    customer interactions. With extensive experience across
                    multiple CRM platforms
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="service-widget">
                <div className="post-media wow fadeIn">
                  <button
                    onClick={() =>
                      onOpenModal('/public/uploads/service_04.jpg')
                    }
                    className="hoverbutton global-radius"
                  >
                    <i className="flaticon-unlink"></i>
                  </button>
                  <img
                    src="/public/uploads/service_04.jpg"
                    alt=""
                    className="img-responsive img-rounded"
                    style={{ height: '230px' }}
                  />
                </div>
                <div className="service-dit">
                  <h3>SEO DEVELOPMENT</h3>
                  <p>
                    Our SEO team provides website optimization, keyword
                    research, content optimization, link building, segmentation
                    analysis, and tracking & analytics to improve search engine
                    rankings & visibility.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
          <hr className="hr1" />

          <div className="text-center">
            <Link
              to="/portfolio"
              className="btn btn-light btn-radius btn-brd grd1"
            >
              Our Portfolio
            </Link>
          </div>
        </div>
      </div>
      {open ? (
        <Modal open={open} onClose={onCloseModal} center>
          <img src={fileName} alt="" className="img-responsive img-rounded" />
        </Modal>
      ) : (
        ''
      )}
    </div>
  );
};

export default Service;
