import Navbar from '../components/navbar/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import { MetaTags } from 'react-meta-tags'

const SalesTrendz = () => {
  return (
    <>
      <MetaTags>
        <title>Sales Trendz - NewItZone</title>
        <meta
          name='description'
          content="In today's competitive business landscape, it's critical to
                    have efficient systems in place to manage your distribution
                    channels and consumer interactions."
        />
        <meta name='keywords' content='HTML, CSS, JavaScript,react,node,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution'></meta>
      </MetaTags>
      <Navbar />
      <div id='about' className='section wb'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_01.png' alt='Distribution Management System logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>Distribution Management System</Link>
                  </h3>
                  <p>In today's competitive business landscape, it's critical to have efficient systems in place to manage your distribution channels and consumer interactions.</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_02.png' alt='Live Tracking and Attendance logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>Live Tracking and Attendance</Link>
                  </h3>
                  <p>Have you ever wished there was a quick and easy way to keep track of work time and employee attendance? So, there is a method now. The time-consuming...</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_03.png' alt='Lead Management System logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>Lead Management System</Link>
                  </h3>
                  <p>It takes a lot of time and effort to gather leads and keep track of them, especially if you only use spreadsheets and guesswork in this regard.</p>
                </div>
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='about-item'>
                <div className='about-icon'>
                  <img src='/public/uploads/service_logo_04.png' alt='Sales Force Automation logo' />
                </div>
                <div className='about-text'>
                  <h3>
                    <Link to='/contact'>Salesforce (CMS) Automation</Link>
                  </h3>
                  <p>Automation has been the need of the hour. SalesForce Automation saves time and resources which leads to an increase in sales and revenue for any company.SalesForce Automation connects your accounting....</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SalesTrendz
