import { useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import { RotatingLines } from 'react-loader-spinner'
import * as Yup from 'yup'
import { MetaTags } from 'react-meta-tags'
import { Link } from 'react-router-dom'
import { office } from '../data'
import { Form, Formik } from 'formik'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Contact = () => {
  const initialValues = {
    name: '',
    email: '',
    mobileNumber: '',
    queryType: '',
    description: '',
  }
  const [loader, setLoader] = useState(false)

  const service_options = ['Support', 'Information', 'Others']

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{7,8}?$/
  const validationSchema = Yup.object({
    name: Yup.string().min(3, 'Too short! The name should be more than 3 characters').required('Please enter your name'),
    email: Yup.string().email('Please enter a valid email id').required('Please enter your email'),
    mobileNumber: Yup.string().matches(phoneRegExp, 'Please enter a valid mobile number').required('Please enter your mobile number'),
    queryType: Yup.string().required('Please select a query type'),
    description: Yup.string().min(15, 'Too short! The message should be more than 15 chars.').required('Please write us your query.'),
  })

  const handleSubmit = async (values, actions) => {
    try {
      const { name, email, mobileNumber, queryType, description } = values
      setLoader(true)
      const { data } = await axios.post('https://newitzone.com/api/query', {
        name,
        email,
        mobileNo: mobileNumber,
        queryType,
        description,
        isSiteChange: 0,
      })
      data.error ? toast.error(data.error) : toast.success(data.message)
      actions.resetForm()
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }
  return (
    <div>
      {loader && (
        <div className='loader'>
          <RotatingLines strokeColor='grey' strokeWidth='5' animationDuration='0.75' width='96' visible={true} />
        </div>
      )}
      <MetaTags>
        <title>Contact - NewItZone</title>
        <meta name='description' content="Need a website but don't know where to start? We can help! We offer consultations to help you choose the right web development service for your needs." />
        <meta name='keywords' content='HTML, CSS, JavaScript,ode,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution'></meta>
      </MetaTags>
      <Navbar />
      <div id='contact' className='section wb'>
        <div className='container'>
          <div className='section-title text-center'>
            <h3>Get in touch</h3>
            <p className='lead'>
              Let us give you more details about the special offer website you want us. Please fill out the form below. <br />
              We have million of website owners who happy to work with us!
            </p>
          </div>
          <ToastContainer position='top-right' autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover theme='light' />
          <div className='row'>
            <div className='col-md-10 col-md-offset-1'>
              <div className='contact_form'>
                <div id='messae'></div>

                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                  {(props) => (
                    <Form className='row'>
                      <fieldset className='row-fluid'>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                            <label className=''>
                              Name <span className='requireSign'>*</span>
                            </label>

                            <input type='text' name='name' value={props.values.name} onBlur={props.handleBlur} onChange={props.handleChange} id='name' className={`form-control ${props.errors.name && props.touched.name ? 'is-invalid' : ''}`} placeholder='Your Name' maxLength='100' />
                            {props.errors.name && props.touched.name ? (
                              <div id='feedback' className='requireSign'>
                                {props.errors.name}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                            <label className=''>
                              Email <span className='requireSign'>*</span>
                            </label>
                            <input type='email' name='email' value={props.values.email} onBlur={props.handleBlur} onChange={props.handleChange} id='email' className={`form-control ${props.errors.email && props.touched.email ? 'is-invalid' : ''}`} placeholder='Your Email' maxLength='100' />
                            {props.errors.email && props.touched.email ? (
                              <div id='feedback' className='requireSign'>
                                {props.errors.email}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                            <label className=''>
                              Mobile Number <span className='requireSign'>*</span>
                            </label>
                            <input
                              type='text'
                              name='mobileNumber'
                              value={props.values.mobileNumber}
                              onBlur={props.handleBlur}
                              onChange={props.handleChange}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              id='mobileNumber'
                              className={`form-control ${props.errors.mobileNumber && props.touched.mobileNumber ? 'is-invalid' : ''}`}
                              placeholder='Mobile Number'
                              maxLength='15'
                            />
                            {props.errors.mobileNumber && props.touched.mobileNumber ? (
                              <div id='feedback' className='requireSign'>
                                {props.errors.mobileNumber}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                            <label className=''>
                              Type of Query <span className='requireSign'>*</span>
                            </label>
                            <select name='queryType' id='select_service' value={props.values.queryType} onBlur={props.handleBlur} onChange={props.handleChange} className='form-control' querydata-style='btn-white'>
                              <option value=''> Select Query Type</option>
                              {service_options.map((value) => (
                                <option value={value} key={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                            {props.errors.queryType && props.touched.queryType ? (
                              <div id='feedback' className='requireSign'>
                                {props.errors.queryType}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <label className=''>
                              Description <span className='requireSign'>*</span>
                            </label>
                            <textarea className='form-control' name='description' value={props.values.description} onBlur={props.handleBlur} onChange={props.handleChange} id='description' rows='6' placeholder='Give us more details..' maxLength='4000'></textarea>
                            {props.errors.description && props.touched.description ? (
                              <div id='feedback' className='requireSign'>
                                {props.errors.description}
                              </div>
                            ) : null}
                          </div>
                          <div className='col-lg-4 col-md-4 col-sm-4 col-sm-offset-4  col-xs-12 text-center'>
                            <button type='submit' id='submit' className='btn btn-light btn-radius btn-brd grd1 btn-block'>
                              Submit
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-offset-1 col-sm-10 col-md-10 col-sm-offset-1 pd-add'>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <div className='address-item'>
                    <div className='address-icon'>
                      <img src='/public/uploads/logo_08.png' alt='contact icon' />
                    </div>
                    <h3>Office Address</h3>
                    <p>{office.address}</p>
                  </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                  <div className='address-item'>
                    <div className='address-icon'>
                      <img src='/public/uploads/logo_07.png' alt='contact icon' />
                    </div>
                    <h3>Contact Us</h3>
                    <p>
                      <Link to='mailto:info@newitzone.com'>
                        <i className='fa fa-envelope-o' aria-hidden='true'></i>
                        &nbsp;{office.email}
                      </Link>
                      <br />
                      <Link to='tel:9716561086'>
                        <i className='fa fa-mobileNumber' aria-hidden='true'></i>
                        &nbsp;{office.mobileNumber}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
