import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
// import Testimonial from '../components/Testimonial/Testimonial';
import Service from '../components/Service/Service';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

const HomeScreen = () => {
  return (
    <div>
      <MetaTags>
        <title>Home - NewItZone</title>
        <meta
          name="description"
          content="Need a website but don't know where to start? We can help! We offer consultations to help you choose the right web development service for your needs."
        />
        <meta
          name="keywords"
          content="HTML, CSS, JavaScript,react,node,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution"
        ></meta>
      </MetaTags>
      <Navbar />
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        items={1}
        nav={1}
        dots={0}
        autoplay={0}
      >
        <div className="item">
          <div className="slider-item home-one-slider-otem slider-item-four slider-bg-one">
            <div className="container">
              <div className="row">
                <div className="slider-content-area">
                  <div className="slide-text">
                    <h1 className="homepage-three-title">
                      Web <span>Development</span> Services
                    </h1>
                    <h2>
                      Our team uses the latest web technologies, such as
                      Node.js, React, Angular and more to create websites that
                      are modern, user-friendly, and fully functional.
                    </h2>
                    <div className="slider-content-btn">
                      <Link
                        to="/about"
                        className="button btn btn-light btn-radius btn-brd"
                      >
                        More details
                      </Link>
                      <Link
                        to="/contact"
                        className="button btn btn-light btn-radius btn-brd"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slider-item text-center home-one-slider-otem slider-item-four slider-bg-two">
            <div className="container">
              <div className="row">
                <div className="slider-content-area">
                  <div className="slide-text">
                    <h1 className="homepage-three-title">
                      Digital <span>Marketing</span> Services
                    </h1>
                    <h2>
                      We specialize in digital marketing services for small and
                      medium-sized businesses. Who want to reach their target
                      audience online.
                    </h2>
                    <div className="slider-content-btn">
                      <Link
                        to="/about"
                        className="button btn btn-light btn-radius btn-brd"
                      >
                        More details
                      </Link>
                      <Link
                        to="/contact"
                        className="button btn btn-light btn-radius btn-brd"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="slider-item home-one-slider-otem slider-item-four slider-bg-three">
            <div className="container">
              <div className="row">
                <div className="slider-content-area">
                  <div className="slide-text">
                    <h1 className="homepage-three-title">
                      Software <span>Development</span> Services
                    </h1>
                    <h2>
                      Want to create amazing apps for your business or project?{' '}
                      With Newitzone, we make it easy for you to create
                      innovative, intuitive and engaging apps for IOS and
                      Android platforms.
                    </h2>
                    <div className="slider-content-btn">
                      <Link
                        to="/about"
                        className="button btn btn-light btn-radius btn-brd"
                      >
                        More details
                      </Link>
                      <Link
                        to="/contact"
                        className="button btn btn-light btn-radius btn-brd"
                      >
                        Contact
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>

      <div id="about" className="section wb">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="message-box">
                <h4>About Us</h4>
                <h2>Creating a Digital World</h2>
                <p className="lead">
                  At newitzone, we are dedicated to providing you with the
                  highest quality software solutions that are tailored to fit
                  your individual needs and business goals.
                </p>

                <p>
                  We offer a wide range of technologies and development
                  services, including HTML, CSS, JavaScript, Node.js, and more.
                  Our experienced developers can create custom solutions that
                  are tailored to your specific needs. Our software solutions
                  are designed for smooth integration in existing systems and
                  platforms for reliable performance. We can help you ensure
                  seamless integration and smooth transition from legacy systems
                  or platforms.
                </p>

                <Link
                  to="/about"
                  className="btn btn-light btn-radius btn-brd grd1"
                >
                  More details
                </Link>
              </div>
            </div>

            <div className="col-md-6">
              <div className="post-media wow fadeIn">
                <img
                  src="/public/uploads/about_01.jpg"
                  alt=""
                  className="img-responsive img-rounded"
                />
                <a
                  href="http://www.youtube.com/watch?v=nrJtHemSPW4"
                  data-rel="prettyPhoto[gal]"
                  className="playbutton"
                >
                  <i className="flaticon-play-button"></i>
                </a>
              </div>
            </div>
          </div>

          <hr className="hr1" />

          <div className="row">
            <div className="col-md-6">
              <div className="post-media wow fadeIn">
                <img
                  src="/public/uploads/about_02.jpg"
                  alt=""
                  className="img-responsive img-rounded"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="message-box">
                <h4>Who We are</h4>
                <h2>We Are Good WEB Solutions</h2>
                <p className="lead">
                  Thousand of our client is using our low price services which
                  including website design, web development, web hosting,
                  e-commerce website development, App development and giving the
                  free recommendation to endorse the services all across the
                  world
                </p>

                <p>
                  We provide website design and development services using the
                  latest coding languages including HTML5, CSS3, JavaScript and
                  PHP, with thorough testing before launch.
                </p>

                <Link
                  to="/about"
                  className="btn btn-light btn-radius btn-brd grd1"
                >
                  More details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="parallax section parallax-off"
        data-stellar-background-ratio="0.9"
        style={{ backgroundImage: `url('/public/uploads/parallax_04.jpg')` }}
      >
        <div className="container">
          <div className="row text-center stat-wrap">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <span data-scroll className="global-radius icon_wrap effect-1">
                <i className="flaticon-briefcase"></i>
              </span>
              <p className="stat_count">1200</p>
              <h3>Complated Projects</h3>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <span data-scroll className="global-radius icon_wrap effect-1">
                <i className="flaticon-happy"></i>
              </span>
              <p className="stat_count">1189</p>
              <h3>Happy Clients</h3>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <span data-scroll className="global-radius icon_wrap effect-1">
                <i className="flaticon-idea"></i>
              </span>
              <p className="stat_count">100+</p>
              <h3>Customer Services</h3>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <span data-scroll className="global-radius icon_wrap effect-1">
                <i className="flaticon-customer-service"></i>
              </span>
              <p className="stat_count">3851</p>
              <h3>Answered Questions</h3>
            </div>
          </div>
        </div>
      </div>
      <Service />
      <div
        className="parallax section noover"
        data-stellar-background-ratio="0.7"
        style={{ backgroundImage: `url('/public/uploads/parallax_05.png')` }}
      >
        <div className="container">
          <div className="row text-center">
            <div className="col-md-6">
              <div className="customwidget text-left">
                <h1>Beautiful Websites</h1>
                <p>
                  Full access control of the background parallax effects, <br />
                  change your awesome background elements and edit colors from
                  style.css or colors.css
                </p>
                <ul className="list-inline">
                  <li>
                    <i className="fa fa-check"></i> Custom Sections
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Parallax's
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Icons & PSD
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Limitless Colors
                  </li>
                </ul>
                <Link
                  to="/services"
                  className="btn btn-light btn-radius btn-brd grd1"
                >
                  More details
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-center image-center hidden-sm hidden-xs">
                <img
                  src="/public/uploads/device_03.png"
                  alt=""
                  className="img-responsive wow fadeInUp"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="features" className="section lb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Features & Overviews</h3>
            <p className="lead">
              Mobile Responsive, and User-Friendly Websites So, you’re thinking
              of creating a website? Great! Nowadays, having an online presence
              is an essential part of any business or personal brand. But let me
              tell you, creating a website can be a daunting task. Where do you
              start? What do you need? What makes a good website? That’s where
              we come in.
            </p>
          </div>

          <div className="row">
            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className="features-left">
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <i className="flaticon-wordpress-logo"></i>
                  <div className="fl-inner">
                    <h4>Mobile Responsive</h4>
                    <p>
                      mobile responsive design refers to the ability of a
                      website
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <i className="flaticon-windows"></i>
                  <div className="fl-inner">
                    <h4>Browser Compatible</h4>
                    <p>
                      Websites should be accessible on all browsers and
                      locations.
                    </p>
                  </div>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <i className="flaticon-price-tag"></i>
                  <div className="fl-inner">
                    <h4>eCommerce Ready</h4>
                    <p>Businesses need secure eCommerce infrastructure.</p>
                  </div>
                </li>
                <li
                  className="wow fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <i className="flaticon-new-file"></i>
                  <div className="fl-inner">
                    <h4>Easy to Customize</h4>
                    <p>
                      We offer custom workspaces with open floor plans and
                      private offices.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-4 hidden-xs hidden-sm">
              <img
                src="/public/uploads/ipad.png"
                className="img-center img-responsive"
                alt=""
              />
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className="features-right">
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <i className="flaticon-pantone"></i>
                  <div className="fr-inner">
                    <h4>Limitless Colors</h4>
                    <p>Our mission is to provide the tools to succeed.</p>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <i className="flaticon-cloud-computing"></i>
                  <div className="fr-inner">
                    <h4>Lifetime Update</h4>
                    <p>we provide Lifetime Update.</p>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <i className="flaticon-line-graph"></i>
                  <div className="fr-inner">
                    <h4>SEO Friendly</h4>
                    <p>We will explore creating an SEO-optimized website.</p>
                  </div>
                </li>
                <li
                  className="wow fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <i className="flaticon-coding"></i>
                  <div className="fr-inner">
                    <h4>Simple Clean Code</h4>
                    <p>
                      Website owners should create a smooth experience for
                      visitors.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <Testimonial /> */}
      <Footer />
    </div>
  );
};

export default HomeScreen;
