import { Link, NavLink } from 'react-router-dom';
import { office } from '../../data';

const Navbar = () => {
  return (
    <div>
      <div className="top-bar">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="left-top">
                <div className="email-box">
                  <Link to="mailto:info@newitzone.com">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    {office.email}
                  </Link>
                </div>
                <div className="phone-box">
                  <Link to={`tel:${office.mobileNumber}`}>
                    <i className="fa fa-phone" aria-hidden="true"></i>{' '}
                    {office.mobileNumber}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="right-top">
                <div className="social-box">
                  <ul>
                    <li>
                      <Link to="/">
                        <i
                          className="fa fa-facebook-square"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i
                          className="fa fa-linkedin-square"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i
                          className="fa fa-twitter-square"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <i className="fa fa-rss-square" aria-hidden="true"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header header_style_01">
        <nav className="megamenu navbar navbar-default">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <NavLink to={'/'} className="navbar-brand">
                <img src="/public/images/logos/logo.png" alt="logo" />
              </NavLink>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <NavLink to={'/'} activeclassname="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/about'} activeclassname="active">
                    About us
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/services'} activeclassname="active">
                    Our Services
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/Sales-Trendz'} activeclassname="active">
                    Sales Trendz
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/ERP-Solutions'} activeclassname="active">
                    ERP Solution
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/Retail-Solutions'} activeclassname="active">
                    Retail Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/portfolio'} activeclassname="active">
                    Portfolio
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to={'/testimonials'} activeclassname="active">
                    Testimonials
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to={'/contact'} activeclassname="active">
                    Contact us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
