import Navbar from '../components/navbar/Navbar'
import Service from '../components/Service/Service'
import Footer from '../components/footer/Footer'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

const ServiceScreen = () => {
  return (
    <div>
      <MetaTags>
        <title>Service - NewItZone</title>
        <meta name='description' content='We offer a wide range of technologies such as software development, SEO development, CRM development, website development, etc. and development services including HTML, CSS, JavaScript, Node.js, React, Angular, and more. Our team of experienced developers is dedicated to delivering high quality solutions that meet your unique business needs.' />
        <meta name='keywords' content='HTML, CSS, JavaScript,react,node,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution'></meta>
      </MetaTags>
      <Navbar />
      <div id='about' className='section wb'>
        <div className='container'>
          <div className='row text-center'>
            <div className='row'>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_20.png' alt='Software logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Software <br />
                        development
                      </Link>
                    </h3>
                    <p>Our team work different technologies like.Svelte Apple MDM Jest Kotlin Dart Microsoft Powerapps MicrosoftFlow Oracle Cloud Platform Apache Knox Gateway Apache we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_22.png' alt='Website logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Website <br />
                        development
                      </Link>
                    </h3>
                    <p>
                      Our team of experts in HTML,CSS,JS,React,Angular
                      <br />
                      Vue.js,Node.js,Ruby,Django and PHP you can design the perfect website for your needs.we deliver the most efficient and cost-effective solutions
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_17.png' alt='IOS logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        IOS App
                        <br /> Development
                      </Link>
                    </h3>
                    <p>Newitzone is your one-stop solution for all IOS App Development needs. With years of experience and a team of experts,we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_18.png' alt='Android logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Android App
                        <br /> Development
                      </Link>
                    </h3>
                    <p>With our team of experienced Android developers and our top-notch support staff, you can rest assured that all your Android App Development needs.we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_19.png' alt='Digital marketing logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Digital <br />
                        marketing
                      </Link>
                    </h3>
                    <p>we leverage the latest digital marketing strategies and technologies to maximize your reach and get you results. we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>

              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_21.png' alt='Server logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Server <br />
                        Management
                      </Link>
                    </h3>
                    <p>Newitzone provides reliable server management solutions that help your business to run more efficiently and securely.Our experienced team of server specialists.we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>

              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_23.png' alt='Accounting logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Accounting
                        <br /> Software
                      </Link>
                    </h3>
                    <p>Newitzone is here to help make your accounting tasks easy and intuitive. With our team of experts, your accounting processes will be simplified and automated.we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_24.png' alt='Database logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>
                        Database
                        <br /> Management
                      </Link>
                    </h3>
                    <p>Newitzone provides complete database administration service like database integration, maintenance, monitoring, optimization, upgrade or performance tuning of your database setting.we deliver the most efficient and cost-effective solutions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Service />
      {/* <Testimonial /> */}
      <Footer />
    </div>
  )
}

export default ServiceScreen
