import Navbar from '../components/navbar/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import { MetaTags } from 'react-meta-tags'

const RetailSolutions = () => {
  return (
    <div>
      <MetaTags>
        <title>Retail Solutions - NewItZone</title>
        <meta name='description' content="Need a website but don't know where to start? We can help! We offer consultations to help you choose the right web development service for your needs." />
        <meta name='keywords' content='HTML, CSS, JavaScript,react,node,Tally Prime,Web Based Payroll,FCA ERP,ERP Solution'></meta>
      </MetaTags>
      <Navbar />
      <div id='about' className='section wb'>
        <div className='container'>
          <div className='row text-center'>
            <div className='row'>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_09.png' alt='Bill logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Quick Bill</Link>
                    </h3>
                    <p>QuickBill POS Software is powerful Point of Sale (POS) software to manage all your Retail operations in a store or distributor location. While the Retail application is used in a store/showroom selling products/services to customers on cash & carry basis, the Distributor application is used by warehouses/stockiest to sell and dispatch goods to retailers on wholesale basis either for cash or on credit. Both have common features</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_10.png' alt='E-invocing logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>E-invocing Software </Link>
                    </h3>
                    <p>e-Invoicing is a critical aspect of GST. Invoicing practices have been quite an industry-specific. Despite the proprietary ways of preparing invoices, unified approach of e-invoicing was taken in good spirit by the overall taxpayers. Since it is a relatively new concept, there are a lot of unanswered questions and apprehensions around this new statutory change</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_11.png' alt='Fusion Retail logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Fusion Retail</Link>
                    </h3>
                    <p>Fusion Retail is a single software for managing retail stores, including POS billing, inventory management, customer loyalty program , financial accounting, payroll management, and store analytics.No matter if you own a single location, fusion retail helps you boost sales and profits.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_12.png' alt='Fusion Restro logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Fusion Restro</Link>
                    </h3>
                    <p>The hotel business is likewise looking for dependable hotel billing software and simplify its financial accounting process. The billing software will help hotel owners create billing reports and efficient ways to bill customers for services. The billing report is used by management to monitor revenue production.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_13.png' alt='Property logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Fusion PMS</Link>
                    </h3>
                    <p>Property owners benefit greatly from a property management software. Property managers have a wide range of extensive and frequently manual front and back office duties.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_14.png' alt='Pharmacy logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Pharmacy Software</Link>
                    </h3>
                    <p>Managing multi-chain pharmacy business operations and end-to-end pharmacy workflow processes has been simpler today. Inventory management, stock management, medical information management, and sales growth are all made possible through pharmacy billing software.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_15.png' alt='Bar Code logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Bar Code Solutions </Link>
                    </h3>
                    <p>Barcode Solution- Gurgaon,Barcode Printers and Barcode Scanners & Barcode Labels offered by Solution 4 retail from Gurgaon, Delhi,noida,faridabad India Thermal Barcode Printers, Barcode Scanners, Barcode Labels,Thermal Transfer Ribbons, Billing Printers, Thermal Billing Rolls,</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <img src='/public/uploads/service_logo_16.png' alt='POS Hardware logo' />
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>POS Hardware</Link>
                    </h3>
                    <p>The CR-4000 Series/CR-4100 Series is medium size cash drawer. Boasting its high reliability and durability,the CR-4000 Series/CR-4100 Series has screw-less tamper-resistant design and robust case. It offers adjustable 5 bill and fixed 9 coin compartments for general use..</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-sm-6'>
                <div className='about-item'>
                  <div className='about-icon'>
                    <span className='icon icon-cloud'></span>
                  </div>
                  <div className='about-text'>
                    <h3>
                      <Link to='/contact'>Tally on Cloud</Link>
                    </h3>
                    <p>Tally on cloud is a cloud-based version of the popular Tally accounting software. Tally on cloud provides businesses with a range of benefits including increased accessibility, improved collaboration, and enhanced security. Here are some key aspects of Tally on cloud that you may find useful:</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RetailSolutions
