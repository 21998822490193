import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="widget clearfix">
                <div className="widget-title">
                  <img src="/public/images/logos/logo-2.png" alt="" />
                </div>
                <p>
                  We offer a wide range of technologies and development
                  services, including HTML, CSS, JavaScript, Node.js, and more.
                  Our experienced developers can create custom solutions that
                  are tailored to your specific needs.
                </p>
                <p>
                  Our software solutions are designed for smooth integration in
                  existing systems and platforms for reliable performance. We
                  can help you ensure seamless integration and smooth transition
                  from legacy systems or platforms.
                </p>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="widget clearfix">
                <div className="widget-title">
                  <h3>Pages</h3>
                </div>

                <ul className="footer-links hov">
                  <li>
                    <Link to="/">
                      Home <span className="icon icon-arrow-right2"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={'/services'}>
                      Our Services
                      <span className="icon icon-arrow-right2"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={'/Sales-Trendz'}>
                      Sales Trendz
                      <span className="icon icon-arrow-right2"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={'/ERP-Solutions'}>
                      ERP Solution
                      <span className="icon icon-arrow-right2"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={'/Retail-Solutions'}>
                      Retail Solutions
                      <span className="icon icon-arrow-right2"></span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/about">
                      About <span className="icon icon-arrow-right2"></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="footer-distributed widget clearfix">
                <div className="widget-title">
                  <h3>Subscribe</h3>
                  <p>
                    We believe that life is short and that it's important to
                    make the most out of every moment. That's why we're
                    constantly on the lookout for new and exciting ways to help
                    you do just that.
                  </p>
                </div>

                <div className="footer-right">
                  <form method="get" action="#">
                    <input
                      placeholder="Subscribe our newsletter.."
                      name="search"
                    />
                    <i className="fa fa-envelope-o"></i>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyrights">
        <div className="container">
          <div className="footer-distributed">
            <div className="footer-left">
              <p className="footer-company-name">
                All Rights Reserved. &copy; {new Date().getFullYear()}
                <Link to="/"> NewItZone </Link>
                Design By :<Link to="/"> NewItZone</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth top={500} />
    </>
  );
};

export default Footer;
